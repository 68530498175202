import React from "react";

import styles from "./MeetMentor.module.css";
import stylesHero from "./HeroComponent.module.css";

import HeadingStroke from "./HeadingStroke";
import { FaInstagram, FaYoutube, FaLinkedin } from "react-icons/fa";

const MeetMentor = ({ data, theme }) => {
  const { mentorName, title, bio, socialCounts, images } = data;

  return (
    <div
      className={`${stylesHero["banner-small"]} ${theme.style === "light" ? stylesHero["banner-dark-theme"] : ""}`}
    >
      <div className={`px-20 ${stylesHero["container-xl-low-padding"]}`}>
        <div className={`${stylesHero["banner-heading-wrapper"]}`}>
          <h2
            className={`text-center ${stylesHero["banner-small-content-heading"]}`}
          >
            {data.pagetitle}
          </h2>
          <HeadingStroke
            className="heading-shape"
            fillColor={theme.gradient1}
          />
        </div>
        <div
          className={`${styles.meetContentWrapper} ${theme.style === "light" ? styles["content-dark-theme"] : ""}`}
        >
          <div className={styles.meetContent}>
            <div className={styles.mentorOpeningContent}>
              <p
                className={styles.meetSubPara}
                dangerouslySetInnerHTML={{
                  __html: `<strong>${mentorName}</strong> is ${title}`,
                }}
              ></p>
            </div>
            <div className={`${styles.meetMainImage} ${styles.hideDesktop}`}>
              <img
                src={images.mainImage}
                loading="lazy"
                sizes="(max-width: 479px) 92vw, (max-width: 767px) 91vw, (max-width: 991px) 92vw, 100vw"
                alt=""
                className={styles.meetImage}
              />
              <div className={styles.followersWrapper}>
                <div className={styles.followers}>
                  <FaInstagram
                    className={styles.social_media_icons}
                    size={27}
                  />
                  <div className={styles.followersText}>
                    {socialCounts.instagram}
                  </div>
                </div>
                <div className={styles.followers}>
                  <FaYoutube className={styles.social_media_icons} size={27} />
                  <div className={styles.followersText}>
                    {socialCounts.youtube}
                  </div>
                </div>
                <div className={styles.followers}>
                  <FaLinkedin className={styles.social_media_icons} size={27} />
                  <div className={styles.followersText}>
                    {socialCounts.linkedin}
                  </div>
                </div>
              </div>
            </div>
            <p
              className={styles.meetPara}
              dangerouslySetInnerHTML={{ __html: `${bio[0]}${bio[1]}` }}
            />
            <div className={`${styles.featuredAnimation} img`}>
              <div id="carousel-image" className={styles.meetSlider}>
                {images.sliderImages.map((src, index) => (
                  <img
                    key={index}
                    src={src}
                    loading="lazy"
                    width="144"
                    height="90"
                    alt=""
                    className={styles.sliderImg}
                  />
                ))}
              </div>
            </div>
            {bio.slice(2).map((paragraph, index) => (
              <p
                key={index}
                className={styles.meetPara}
                dangerouslySetInnerHTML={{ __html: paragraph }}
              />
            ))}
          </div>
          <div className={`${styles.meetMainImage} ${styles.hideMobile}`}>
            <div className={styles.followersWrapper}>
              <div className={styles.followers}>
                <FaInstagram className={styles.social_media_icons} size={27} />
                <div className={styles.followersText}>
                  {socialCounts.instagram}
                </div>
              </div>
              <div className={styles.followers}>
                <FaYoutube className={styles.social_media_icons} size={27} />
                <div className={styles.followersText}>
                  {socialCounts.youtube}
                </div>
              </div>
              <div className={styles.followers}>
                <FaLinkedin className={styles.social_media_icons} size={27} />
                <div className={styles.followersText}>
                  {socialCounts.linkedin}
                </div>
              </div>
            </div>
            <img
              src={images.mainImage}
              loading="lazy"
              sizes="(max-width: 991px) 100vw, (max-width: 1279px) 51vw, (max-width: 1919px) 606.8515625px, 604px"
              alt=""
              className={styles.meetImage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetMentor;
